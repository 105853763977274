<template>
    <b-card
      body-class="pb-50"
    >
    <h6>{{ title }}</h6>
    <vue-table-dynamic
      ref="refTable"
      :params="fetchData">
      </vue-table-dynamic>
      </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BCard,
    // VueApexCharts,
  },
  props: {
    data: {
      type: Array,
    },
    title: {
        type: String,
    },
  },
  data() {
    return {
        fetchData: {
          data: this.data,
          header: 'row',
          enableSearch: true,
          scrollbar: 'show',
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 400 }, { column: 2, width: 100 }, { column: 3, width: 200 }, { column: 4, width: 100 }, { column: 5, width: 100 }],
        },
    }
  },
  methods: {
    kFormatter(num) {
      if (num !== 0) {
       const numTemp = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))
       return Math.abs(num) > 999 ? `${numTemp}k` : num
      }
      return num
    },
  },
}
</script>
