<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="12"
            md="12"
            cols="12"
          >
          <template>
  <b-card
    body-class="pb-50"
    border-variant="danger"
    header-border-variant="danger"
    header-text-variant="danger"
  >
    <h6>Information</h6>
    {{ information ? information:'There is no current information' }}
  </b-card>
</template>
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingMR" :title='`Pending MR`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingPRLocal" :title='`Pending PR Local Asset/FGS`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingPRSupplies" :title='`Pending PR Local Supplies`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingPRHO" :title='`Pending PR HO`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingPOLocal" :title='`Pending PO Local`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-count :data="pendingPOHO" :title='`Pending PO HO`' />
          </b-col>
          <b-col
            lg="6"
            md="6"
            cols="6"
          >
            <ecommerce-count :data="totalPRPlan" :title='`Total PR Plan`' />
          </b-col>
          <b-col
            lg="12"
            md="12"
            cols="12"
          >
            <ecommerce-table :data="dataTable" :title='`Item Needs To Restock`' />
          </b-col>
          <b-col
            lg="12"
            md="12"
            cols="12"
          >
          <template>
    <b-card
      body-class="pb-50"
    >
    <h6>Cumulative Data </h6>
    <vue-apex-charts
      v-if="series.length > 0"
      type="pie"
      height="350"
      :options="chartOptions"
      :series="series">
    </vue-apex-charts>
        </b-card>
        <b-card
      body-class="pb-50"
    >
    <h6>This Year Data </h6>
    <vue-apex-charts
      v-if="seriesThisYear.length > 0"
      type="pie"
      height="350"
      :options="chartOptionsThisYear"
      :series="seriesThisYear">
    </vue-apex-charts>
        </b-card>
        </template>
          </b-col>
          </b-row>
          </b-col>
      <!-- Revenue Report Card -->

      <!--/ Revenue Report Card -->
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceCount from './EcommerceCount.vue'
import EcommerceTable from './EcommerceTable.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
// import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
// import EcommerceMeetup from './EcommerceMeetup.vue'
// import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
// import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
// import EcommerceTransactions from './EcommerceTransactions.vue'

const pendingMR = 0
const finishedMR = 0
const totalPRPlan = 0
const pendingPRLocal = 0
const pendingPRSupplies = 0
const pendingPRHO = 0
const pendingPOLocal = 0
const pendingPOHO = 0
const totalItems = 0
const total = 0
const series = []
const chartOptions = {}
const seriesThisYear = []
const chartOptionsThisYear = {}
const dataTable = [['No', 'Item', 'Item Code', 'Current Stock', 'Min Stock']]
const information = ''
export default {
  components: {
    BCard,
    BRow,
    BCol,
    // EcommerceRevenueReport,
    EcommerceCount,
    EcommerceTable,
    // EcommerceEarningsChart,
    // EcommerceCompanyTable,
    // EcommerceMeetup,
    // EcommerceBrowserStates,
    // EcommerceGoalOverview,
    // EcommerceTransactions,
    VueApexCharts,
  },
  data() {
    return {
      series,
          chartOptions,
          seriesThisYear,
          chartOptionsThisYear,
      information,
      data: {},
      dataTable,
      totalPRPlan,
      pendingMR,
      finishedMR,
      pendingPRLocal,
      pendingPRSupplies,
      pendingPOLocal,
      pendingPRHO,
      pendingPOHO,
      totalItems,
      total,
    }
  },
  methods: {
    async getStatistics() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  Authorization: `Bearer ${userToken}`,
  'Content-Type': 'application/json',
}
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PO_SUMMARY_PIE}`
          axios
              .get(url, { headers }).then(res => {
              if (typeof res !== 'undefined') {
          if (res.data.success === true) {
            this.series = res.data.data.series
            this.chartOptions = res.data.data.chartOptions
          } else {
            console.log()
            }
          } else {
            this.$router.push({ name: 'auth-login' })
          }
        })
      },
    async getStatisticsThisYear() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  Authorization: `Bearer ${userToken}`,
  'Content-Type': 'application/json',
}

          const currentYear = new Date().getFullYear()

          // Set dateStart to 1st January of the current year
          const dateStart = `${currentYear}-01-01`

          // Set dateEnd to 31st December of the current year
          const dateEnd = `${currentYear}-12-31`
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PO_SUMMARY_PIE}?date_start=${dateStart}&date_end=${dateEnd}`
          axios
              .get(url, { headers }).then(res => {
              if (typeof res !== 'undefined') {
          if (res.data.success === true) {
            this.seriesThisYear = res.data.data.series
            this.chartOptionsThisYear = res.data.data.chartOptions
          } else {
            console.log()
            }
          } else {
            this.$router.push({ name: 'auth-login' })
          }
        })
      },
      async getDashboard() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  Authorization: `Bearer ${userToken}`,
  'Content-Type': 'application/json',
}
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DASHBOARD}`
          axios
              .get(url, { headers }).then(res => {
              if (typeof res !== 'undefined') {
          if (res.data.success === true) {
            this.pendingMR = res.data.data.pendingMR
            this.finishedMR = res.data.data.finishedMR
            this.pendingPRLocal = res.data.data.pendingPRLocal
            this.pendingPRSupplies = res.data.data.pendingPRSupplies
            this.pendingPRHO = res.data.data.pendingPRHO
            this.pendingPOLocal = res.data.data.pendingPOLocal
            this.pendingPOHO = res.data.data.pendingPOHO
            this.totalItems = res.data.data.totalItems
            this.totalPRPlan = res.data.data.prPlan
            let i = 1
              res.data.data.itemStock.map(o => {
              const row = []
                  row.push(i)
                  row.push(o.item_name)
                  row.push(o.item_code)
                  row.push(o.item_stock_current_available)
                  row.push(o.item_min_stock_number)
                  dataTable.push(row)
                  i += 1
              })
          } else {
            console.log()
            }
          } else {
            this.$router.push({ name: 'auth-login' })
          }
        })
      },
      async emptyTable() {
        const header = ['No', 'Item', 'Item Code', 'Current Stock', 'Min Stock']
        dataTable.length = 0
        while (dataTable.length > 0) {
          dataTable.pop()
        }
        dataTable.push(header)
      },
  },
  created() {
    // data
        const userData = getUserData()
        const firstName = userData.user_fullname.charAt(0).toUpperCase() + userData.user_fullname.slice(1)
        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Welcome ${firstName} to Procurement`,
                    },
                  })
      this.emptyTable()
  },
  mounted() {
      this.emptyTable()
      this.getDashboard()
      this.getStatistics()
      this.getStatisticsThisYear()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
